<template>
  <div class="animated fadeIn">
    <add-service :type="'outClinic'" :label="'در منزل'" :back="'/admin/homeServices'"/>
  </div>
</template>
<script>
const AddService = () => import("@/components/services/AddService");

export default {
  components: {
    AddService
  }
};
</script>

